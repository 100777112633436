import React from "react";
const HeroSection = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  };
  return (
    <main id="hero-section">
      <div className="hero-info">
        <h1 className="hero-heading">
          TIME<span>is</span>EVERYTHING
        </h1>
        <p className="hero-paragraph">
          <strong>
            We generate premium, highly-qualified leads specialized in
            Forex/Crypto trading. If you're looking to supercharge your business
            with motivated, ready-to-convert leads, we are your trusted partner.
            Our proven track record speaks for itself with over 100+ satisfied
            subscribers.
          </strong>
          <br />
        </p>
        <div className="hero-action-buttons">
          <button onClick={scrollToContact} className="btn primary-btn">
            BOOK A CALL NOW
          </button>
        </div>
      </div>
      <div className="hero-section-img-container">
        <div className="background-ele"></div>
        <img
          src="/header.png"
          data-aos="zoom-out"
          className="hero-img"
          alt="Hero"
        />
        <a
          href="https://www.instagram.com/learnfury/"
          className="hero-img-social-link"
          data-aos="zoom-out"
          data-aos-delay="450"
        >
          <img src="/instagram.png" alt="Instagram" />
        </a>
        <div className="subscribers" data-aos="zoom-out" data-aos-delay="300">
          <h1>1.50M+</h1>
          <p>Subscribers</p>
        </div>
        <div className="hero-review" data-aos="zoom-out" data-aos-delay="600">
          <i className="fa-solid fa-quote-left"></i>
          <p>
            When I started my page, I didn't know how I could make it
            successful. These guys just helped me make my dream come true.
          </p>
        </div>
      </div>
    </main>
  );
};
export default HeroSection;
